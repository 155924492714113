
import { textSearch } from "@/assets/ts/_utils";
import { toLocaleDateString } from "@/assets/ts/_utils/formatters";
import JsonExcel from "@/components/widgets/tables/JsonExcel.vue";
import { municipiosComarcas } from "@/core/data/municipiosProvinciasComarcas";
import { SortProps, sortFn } from "@/core/helpers/sort";
import { ServicioEstado } from "@/enums";
import { ServicioComun } from "@/store/models";
import { AuthModule } from "@/store/modules";
import { ErrorMessage, useField } from "vee-validate";
import { computed, defineComponent, ref, watch } from "vue";
import { useArrayPagination } from "vue-composable";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "servicios-listado",
  components: { "download-excel": JsonExcel, ErrorMessage },
  props: {
    servicios: Object as () => ServicioComun[] | null,
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.familiaPrioritaria) {
        return "warning-row";
      }
    },
  },
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const authModule = getModule(AuthModule, store);
    const comarcaId = authModule.user.comarcaId;

    const csvLabels = {
      tipoServicioValor: t("csv.solicitudes.servicio.tipoServicioValor"),
      nombreServicio: t("csv.solicitudes.servicio.nombreServicio"),
      nombreComarca: t("csv.solicitudes.servicio.comarca"),
      fecha: t("csv.solicitudes.servicio.fecha"),
      responsable: t("csv.solicitudes.servicio.responsable"),
      dni: t("csv.solicitudes.servicio.dni"),
      telefono: t("csv.solicitudes.servicio.telefono"),
      menores: t("csv.solicitudes.servicio.menores"),
      ingresos: t("csv.solicitudes.servicio.ingresos"),
      familiaPrioritaria: t("csv.solicitudes.servicio.familiaPrioritaria"),
      estadoValor: t("csv.solicitudes.servicio.estado"),
      localidad: t("csv.solicitudes.servicio.localidad"),
    };

    const csvFields = [
      "tipoServicioValor",
      "nombreServicio",
      ...(!comarcaId ? ["nombreComarca"] : []),
      "fecha",
      "responsable",
      "dni",
      "telefono",
      "localidad",
      "menores",
      "ingresos",
      "familiaPrioritaria",
      "estadoValor",
    ];
    const csvName = "serviciosListado" + new Date().getTime() + ".xlsx";

    const search = ref("");

    const handleEdit = (index, row) => {
      if (row.tipoServicio === "SERVICIO_PROGRAMADO") {
        router.push(`/listados/servicios-programados-solicitudes/${row.id}`);
      } else {
        router.push(`/listados/servicios/${row.id}`);
      }
    };

    const sortEstado = function (a, b) {
      if (a.estado < b.estado) return -1;
      if (a.estado > b.estado) return 1;
      return 0;
    };
    const sortIngresos = function (a, b) {
      let primero = isNaN(a.ingresos) ? 0 : a.ingresos;
      let segundo = isNaN(b.ingresos) ? 0 : b.ingresos;

      return primero - segundo;
    };

    watch(
      () => props.servicios,
      () => {
        currentPage.value = 1;
      }
    );

    const servicios = computed(() => {
      // NOTA: Necesario para exportar los campos
      return props.servicios?.map((ele) => {
        return {
          ...ele,
          nombreComarca: ele.comarca?.nombre,
          fecha: new Date(ele.createdOn).toLocaleDateString("es-ES"),
        };
      });
    });

    const sortProps = ref<SortProps>({
      prop: "createdOn",
      order: "descending",
      method: null,
    });

    const filteredCollection = computed(() =>
      (servicios.value ?? [])
        .slice()
        .sort(sortFn(sortProps))
        .filter(
          (data) =>
            !search.value ||
            textSearch(!comarcaId ? data.nombreComarca : "", search.value) ||
            textSearch(data.responsable, search.value) ||
            textSearch(data.dni, search.value) ||
            textSearch(data.telefono, search.value) ||
            textSearch(data.menores, search.value) ||
            textSearch(data.nombreServicio, search.value) ||
            textSearch(data.localidad, search.value)
        )
    );

    const { value: localidad } = useField("localidad");
    const { value: filtroFechas } = useField("filtroFechas");

    const serviciosListado = computed(() => {
      const filteredBySearch = (servicios.value ?? [])
        .slice()
        .sort(sortFn(sortProps))
        .filter(
          (data) =>
            !search.value ||
            textSearch(!comarcaId ? data.nombreComarca : "", search.value) ||
            textSearch(data.responsable, search.value) ||
            textSearch(data.dni, search.value) ||
            textSearch(data.telefono, search.value) ||
            textSearch(data.menores, search.value) ||
            textSearch(data.nombreServicio, search.value) ||
            textSearch(data.localidad, search.value)
        );

      if (
        localidad?.value !== undefined &&
        localidad?.value !== "" &&
        (filtroFechas?.value === undefined ||
          filtroFechas?.value === null ||
          filtroFechas?.value === "")
      ) {
        // Intersección de registros filtrados por búsqueda y por localidad
        return filteredBySearch?.filter((data) =>
          servicios.value
            ?.filter((data) => data.localidad == localidad.value)
            ?.includes(data)
        );
      }

      if (
        filtroFechas?.value !== undefined &&
        filtroFechas?.value !== null &&
        filtroFechas?.value !== "" &&
        (localidad?.value === undefined || localidad?.value === "")
      ) {
        // Intersección de registros filtrados por búsqueda y por fechas
        return filteredBySearch?.filter((data) =>
          servicios.value
            ?.filter((data) => {
              const fechaCreacion = new Date(data.createdOn);
              const fechaInicio: Date = (filtroFechas.value as Date[])[0];
              const fechaFin: Date = (filtroFechas.value as Date[])[1];
              return fechaInicio <= fechaCreacion && fechaFin >= fechaCreacion;
            })
            ?.includes(data)
        );
      }

      if (
        filtroFechas?.value !== undefined &&
        filtroFechas?.value !== null &&
        filtroFechas?.value !== "" &&
        localidad?.value !== undefined &&
        localidad?.value !== ""
      ) {
        // Intersección de registros filtrados por búsqueda, por localidad y por fechas
        return filteredBySearch?.filter((item) => {
          const fechaCreacion = new Date(item.createdOn);
          const fechaInicio: Date = (filtroFechas.value as Date[])[0];
          const fechaFin: Date = (filtroFechas.value as Date[])[1];

          const fechaInRange =
            fechaInicio <= fechaCreacion && fechaFin >= fechaCreacion;
          const localidadMatch = item.localidad === localidad.value;

          return fechaInRange && localidadMatch;
        });
      }

      return filteredBySearch;
    });

    const municipiosComarca = computed(() => {
      if (!comarcaId) {
        return municipiosComarcas;
      } else {
        return municipiosComarcas.filter(
          (municipio) => municipio.comarcaId === comarcaId
        );
      }
    });

    const { result, currentPage, lastPage } = useArrayPagination(
      serviciosListado,
      { pageSize: 100 }
    );

    return {
      serviciosListado,
      filteredCollection,
      isAdmin: !comarcaId,
      csvLabels,
      csvFields,
      csvName,
      handleEdit,
      sortIngresos,
      sortEstado,
      search,
      ServicioEstado,
      toLocaleDateString,
      sortChange: ({ column, prop, order }) => {
        sortProps.value = { prop, order, method: column.sortMethod };
      },
      result,
      currentPage,
      lastPage,
      handleCurrentPageChange: (page: number) => (currentPage.value = page),
      municipiosComarca,
      localidad,
      filtroFechas,
    };
  },
});
