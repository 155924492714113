
import ServiciosTabla from "@/components/servicios/TablaSolicitados.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ServicioEstado } from "@/enums/servicio-estado.enum";
import Page from "@/layout/Page.vue";
import { ServicioComun } from "@/store/models";
import {
  AuthModule,
  ServicioListadoModule,
  ServiciosProgramadosModule,
} from "@/store/modules";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "servicios-listado",
  components: { Page, ServiciosTabla },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const moduleServicios = getModule(ServicioListadoModule, store);
    const moduleServiciosColectivos = getModule(
      ServiciosProgramadosModule,
      store
    );
    const authModule = getModule(AuthModule, store);
    const comarcaId = authModule.user.comarcaId;

    const error = computed(() => moduleServicios.errors);

    watch(error, () => {
      if (!error.value) return;

      Swal.fire({
        text:
          t("errors." + (error.value.error.code ?? error.value.error.name)) ??
          error.value.error.name,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t("dialogs.okButton"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    });
    const cargaListado = ref(false);

    onBeforeMount(async () => {
      cargaListado.value = true;
      try {
        await Promise.all([
          moduleServicios.fetchServiciosSolicitados(comarcaId),
          moduleServiciosColectivos.fetchServiciosColectivosSolicitados(
            comarcaId
          ),
        ]);
      } catch (err) {
        Swal.fire({
          text: t("formularios.serviciosSolicitadosListado.cargar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        cargaListado.value = false;
      }
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs(t("menu.serviciosSolicitados"), [
        t("menu.solicitudes"),
      ]);
    });

    return {
      servicios: computed(() => {
        const serviciosIndividualesFiltrados =
          moduleServicios.serviciosListado?.filter(
            (ele) => ele.estado !== ServicioEstado.incorporado
          );
        const serviciosIndividuales =
          serviciosIndividualesFiltrados?.map<ServicioComun>((ele) => {
            return {
              id: ele.id || "",
              estado: ele.estado || "",
              createdOn: ele.createdOn,
              tipoServicio: "SERVICIO_INDIVIDUAL",
              tipoServicioValor: t("tipoServicio.individual"),
              estadoValor: t("estados." + ele.estado || ""),
              responsable: [ele.familia?.nombre, ele.familia?.apellidos].join(
                " "
              ),
              dni: ele.familia?.dni || "",
              telefono: ele.familia?.telefono || "",
              conIngresos: ele.familia?.conIngresos || false,
              ingresos: ele.familia?.ingresos || "",
              familiaPrioritaria: ele.familia?.familiaPrioritaria || false,
              localidad: ele.familia?.localidad || "",
              menores: ele.menores
                .map((m) => m.nombre + " " + m.apellidos)
                .join(", "),
              comarcaId: ele.comarcaId,
              comarca: ele.comarca,
              nombreServicio: ele.servicioProgramado?.nombre || "",
            };
          });

        const serviciosColectivosFiltrados =
          moduleServiciosColectivos.serviciosColectivos?.filter(
            (ele) => ele.estado !== ServicioEstado.incorporado
          );
        const serviciosColectivos =
          serviciosColectivosFiltrados?.map<ServicioComun>((ele) => {
            return {
              id: ele.id || "",
              estado: ele.estado || "",
              createdOn: ele.createdOn,
              tipoServicio: "SERVICIO_PROGRAMADO",
              tipoServicioValor: t("tipoServicio.colectivo"),
              estadoValor: t("estados." + ele.estado || ""),
              responsable: ele.familia?.nombre
                ? [ele.familia?.nombre, ele.familia?.apellidos].join(" ")
                : [
                    ele.peticionFormulario?.datosSolicitud?.nombre,
                    ele.peticionFormulario?.datosSolicitud?.apellidos,
                  ].join(" "),
              dni:
                ele.familia?.dni ||
                ele.peticionFormulario?.datosSolicitud?.dni ||
                ele.datosSolicitud?.dni ||
                "",
              telefono:
                ele.familia?.telefono ||
                ele.peticionFormulario?.datosSolicitud?.telefono ||
                "",
              localidad:
                ele.familia?.localidad ||
                ele.peticionFormulario?.datosSolicitud?.localidad ||
                ele.datosSolicitud?.localidad ||
                "",
              conIngresos: ele.familia?.conIngresos || false,
              ingresos: ele.familia?.ingresos || "",
              familiaPrioritaria: ele.familia?.familiaPrioritaria || false,
              menores: ele.datosSolicitud?.menores
                .map((m) => m.nombre + " " + m.apellidos)
                .join(", "),
              comarcaId: ele.comarca?.id,
              comarca: ele.comarca,
              nombreServicio: ele.servicioProgramado?.nombre,
            };
          });
        return serviciosColectivos.concat(serviciosIndividuales);
      }),
      cargaListado,
    };
  },
});
