import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ServiciosTabla = _resolveComponent("ServiciosTabla")!
  const _component_Page = _resolveComponent("Page")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_Page, null, {
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_ServiciosTabla, { servicios: _ctx.servicios }, null, 8, ["servicios"]), [
        [_directive_loading, _ctx.cargaListado]
      ])
    ]),
    _: 1
  }))
}